// const carpeDiem = {};\
import images from "./images";

export const carpeDiem = {
  files: [
    {
      title: "Magni-Fish-Cent 2k21- Metal Scrap",
      description: `Magni-Fish-cent is a little fish found in the western coast of Africa. 
    This little fish had all available resources in the western coast to make him contented but sadly, 
    he was always depressed as he feels he is small. "It's discouraging and tedious to be such a little fish!" 
    He would always murmur this at any given opportunity. He always believed if he was larger he would be fulfilled until 
    he was swimming along the coast along with rest of the school and suddenly, without no warning they found themselves in the 
    meshes of a great net being thrown into the sea by some great fishermen. He managed to wriggle his way out of the mesh as 
    this was only possible because he was the smallest of the school. He watched in fear and anguish as the net trapping the 
    remaining school of fishes was drawn up out of the water into sunlight. It was at this point he understood the sentiment of 
    doing something grand, living for the moment and making the most out of today, because one doesn't know if they'll live to see tomorrow. 
    Ever since, he swam here and there and everywhere with a genuine joyful and enthusiastic soul utilising every opportunity and resources 
    readily available at that given period. He lived happily after, taking a day at a time without making the worries of tomorrow or unknown 
    impede his present situation or functions.`,
      img: [images.magniFishcent2, images.magniFishcent],
      category: "carpiediem",
      year: "2021",
    },
    {
      title: "Humming Lad Year- 2k21 Medium- Metal Scrap",
      description: `
    This Humming Lad takes delight in the small little things of life. Spreading its wings and letting its 
    spirit soar as it darts eagerly and sweetly dipping into the flower nectar. Just like this humming lad,
    have a pure spirit of joy as the sweetest nectar you will find is within you and always remember
    tomorrow is not guaranteed, develop spontaneous behaviour and to make the most out of today
    `,
      category: "carpiediem",
      year: "2021",
      img: [images.humminglad, images.humminglad2],
    },
    {
      title: "Jehrie the Mouse Year- 2k21 Medium- Metal Scrap",
      description: `
    Jehrie the Mouse is a piece created in synergy to Jerry Mouse. 
    Jerry Mouse is a fictional character in Metro-Goldwyn Mayer's series of Tom and Jerry theatrical cartoon short films. 
    Created by William Hanna and Joseph Barbera. Jerry is usually described as an excited, 
    carefree and cheeky mouse, he in several episodes is shown to be cold as he seeks to have fun no matter what. 
    Jerry the mouse as a character was of the sentiment of doing something grand and living for the moment. He had a spontaneous behaviour and was making the most out of his day, 
    because he doesn't know what could show up the next minute. A proper 'Carpe Diem' character!
    `,
      category: "carpiediem",
      year: "2021",
      img: [images.jehriemouse, images.jerrythemouse, images.jerrythemouse2],
    },
  ],
  title: "Carpie Diem",
  img: images.magniFishcent,
};

export const roadBkToYou = {
  files: [
    {
      title: "Identity Year- 2021 Medium - Metal Junks",
      description: `
    Looks can't hide your real identity. Without strings (broken or unfixed) a guitar could be recognised. 
    Recognising a guitar goes more beyond its affliction with the strings. A broken string seldom has the right spare. 
    In reality, we all are like guitars. We have individual identities regardless of the situation we are going through just 
    as seen with the guitar in the frame above which unfortunately has gotten its strings broken. Regardless of what you are going 
    through. Do not forget who you really are as real people would also neglect what you are going through at that moment and recognise 
    the stuff you are made of. Don't look like what you are going through! Don't let your Struggles become your Identity!
`,
      category: "The Road back to you",
      year: "2021",
      img: [images.identity, images.identityb],
    },
    {
      title: "Sync Master Year- 2021 Medium - Metal Junks",
      description: `
    We are like Chameleons, we take our hue and the colour of our moral character from our surrounding. 
    Best believe that synchronising ourselves with the right surroundings/ideology helps
    refining our identity and boosts our growth level in all ramifications
`,
      category: "The Road back to you",
      year: "2021",
      img: [images.sync_mastee2, images.sync_mastee3, images.sync_mastee4],
    },
    {
      title: "Roe-2n-Deer Year- 2021 Medium - Metal Junks",
      description: `
    A wounded deer leaps the highest- Emily Dickson A deer suffering from a fatal wound instead of decelerating, the wound makes it take the 
    highest leap before falling dead. This is a metaphor for people hiding their emotional distress behind their appearance of normalcy. It 
    addresses the idea that the deer musters all its strength to take one last leap of life instead of decelerating and despairing about the wound. 
    Taking Moses in the bible for Example, while he had to strike the rocking Mount Horeb to provide water for thirsty Israelites. People struck by 
    anguish or pain have the capability to transform their pain into something remarkable. Don't give up! After a great pain comes a formal feeling!! 
    You are on the road back to you!!
`,
      category: "The Road back to you",
      year: "2021",
      img: [images.roe_2d_deer, images.roe_2d_deerb],
    },
  ],
  title: "The `Road Back To You",
  img: images.identity,
};

export const outlookOfLife = {
  files: [
    {
      title: "Heat-Maker Year- 2021 Medium - Metal Junks",
      description: `HEAT-MAKER is a creative recreation of the “Grammy Awards”, popularly nicknamed “the award for hit-makers” (pun intended), using a local electric cooker owned by the artist during his years as an undergraduate, and a trumpet. The inspiration behind this novel piece, is the “heat” one must go through before achieving success, much like the refining of raw elements through fire to get pure gold. 
      The cooker provides the heat needed for the contents on it to gain momentum and an excited state to produce the “delicious” end result desired while the trumpet produces a beautiful, unmissable sound. These two signify together; the ‘heat’ process that must be passed through to be a “hit-maker”, so much that your arrival will be as unmissable as the delectable sound of the brass wind instrument. Similarly, in real 
      life situations, to be accorded the deserved recognition in any craft, one must be able to go through “heat”. This heat can come in the form of criticism, lack of funds, support, and/or motivation at some point, etc. but moving 
      forward and overcoming these challenges is imperative to attaining the ultimate victory desired. In the entertainment world, for example, the Grammy is the most sought-after award, the ultimate dream of most musicians, but to be deserving of such an award requires years of hit songs, piled accomplishments, and recognition. This is the same for every craft and a principle every honourable creative has to abide by. 
      HEAT MAKER was created to be an inspiring piece, reminding people that “the ‘heat’ is normal and necessary... just keep your eyes on the Grammy.”`,
      category: "Outlook of life",
      year: "2021",
      img: [images.Heatmaker],
    },
    {
      title: "High Hopes Heron Medium - Metal Junks Year – 2021",
      description: `Faith is the bird that feels the light when the dawn is still dark. Even having fulfilled all requirements of 
      life and results are not forthcoming, faith gives you inner strength and a sense of balance and perspective in life. 
      Notable people in the world today had faith in their ideas and their skills even people tagged them as crazy. Faith 
      is a passionate intuition. Keep pushing, better times ahead.`,
      category: "Outlook of life",
      year: "2021",
      img: [images.High_hopes_heron, images.High_hopes_heron2],
    },
    {
      title: "Hypnotic Stare Medium - Metal Scrap Year - 2021",
      description: `Hypnosis as a general time is believed as the act of subjecting individuals to do things against their will. This is actually not true, but making the individual rather realising how much control they actually have over their own mind and bodies. Mind is the gateway to the unconscious, when inner powers of discrimination associated with our personal wills and passively allow ideas and input 
      into our subconscious mind, we are open to suggestions which over time can be directed in specific ways. TV commercials persuade viewers thinking they need the product. Mind opens naturally and it is similar the way children can quickly learn and adapt to their surroundings. Hypnosis is a state of consciousness characterised by increased receptivity to suggestions. When people think getting shot will hurt,
       most pain experienced are psychological which actually leads to physical problem also all problems are illusions of the mind. Hypnotic Stare art piece is describing a state where an individual is in supposed state of pain and anguish. It might be mostly hidden to the third party but signals can be picked by a very observant third party through the dark shadows of the eyes and eyes so lost in heavy confusion. 
       Pain is not a true reflection of the actual situation on ground but actual illusions of the mind, Just as during hypnotic stare. On the positive side, hypnotic state makes a person better able to respond to suggestions (pain and anguish).
      Pain control may improve how you feel and function in personal and professional relationships, as
      `,
      category: "Outlook of life",
      year: "2021",
      img: [images.hypnotic_stare],
    },
    {
      title: "Rat Race",
      description: `Rat race refers to an endless, self-defeating, or pointless pursuit. The term comes from the race between two rats to get the piece of cheese at 
      the other of the room first by trying to outrun each other. 
      In the process, they spend more energy than the reward is worth. 
      It signifies an endless, futile pursuit, a mad competition one embarks on.
      It may also refer to a competitive struggle to get ahead financially or routinely. The secret is there is no hack to life.
      We Should just endeavour to live and enjoy every second.<br/>
      Most people don't live; they just race. They are trying to reach some goal far away on the horizon, and in the heat of the going they get so breathless and panting that they lose sight of the beautiful, tranquil country they are passing through; and then the first thing they know, they are old and worn out, and it doesn't make any difference whether they've reached the goal or not`,
      category: "Outlook of life",
      year: "2021",
      img: [images.Ratrace],
    },
  ],
  title: "Outlook of Life",
  img: images.High_hopes_heron,
};

export const owlSeries = {
  files: [
    {
      title: "1’ Owl Clock (Vision 20nil)",
      description: `Owl clock is a sculptural piece assessing the progress of the artist’s home country(Nigeria) over the years till date. The owl bird represents the deep connection that you share with wisdom, good judgement and knowledge. The Owl sculpture has one of its eyes as clock. It was predicted years back that the country will be far better than other African countries by year 2020.
      Year 2020 has passed! All that is visible is recycling of familiar old corrupt politicians, going about a circle in all sectors and government policies just like the hand of the clock. Year twenty nil depicts a projected time with no visible change.`,
      category: "Owl series",
      year: "2020",
      img: [images._1owlClock, images._1owlClockb],
    },
    {
      title: "2’ Owl Clock (Vision 20nil)",
      description: `2’ Owl Clock (Vision 20nil) is an upgrade of 1’ Owl Clock (Vision 20nil). It was created at about
      a year interval`,
      category: "Owl series",
      year: "2021",
      img: [images.owlClock, images.owlClock2],
    },
    {
      title: "Owl-some",
      description: `Advice from an Awesome Owl (Owl-some): be “whoo’ you are, stay focused, trust in a wise friend, live off the land, glide through the dark times, be observant, life’s a hoot and lastly, remember to be Owlsome`,
      category: "Owl series",
      year: "2020",
      img: [images.owl_some],
    },
  ],
  title: "Owl Series",
  img: images._1owlClock,
};

export const _3muskeerteers = {
  files: [
    {
      title: "Paw-sitive (+)",
      description: `Paw-sitive is a whippet dog known for his positivity, as he is always looking out to the stars. He believes nothing is actually impossible. In his words, he will always say “Keep your eyes on the stars and your feet on the ground. Keep being positive (+)”`,
      category: "3 musketeers",
      year: "2022",
      img: [images.pawSitive, images.pawSitive_2],
    },
    {
      title: "John Hop",
      description: `John hop the rabbit is an opportunity taker. He is of the belief a wise bunny knows carrot will not hop to him. He sees opportunities instead of obstacles because he believes opportunities are never lost; someone will take the ones you miss.`,
      category: "3 musketeers",
      year: "2022",
      img: [images.johnHop, images.johnHop_2],
    },
    {
      title: "Herald",
      description: `Herald a parrot in the midst of land animals. He is the standout amongst the trio, as his things are done differently.
      These three jolly friends could be likened to the mind-set of a winner. A winner must at all times have a positive mind-set, be able to see opportunities instead of obstacles and while taking the opportunities he does his/her best to always standout of his peers.`,
      category: "3 musketeers",
      year: "2022",
      img: [images.Herald, images.Herald_2],
    },
  ],
  title: "3 musketeers",
  img: images.Herald,
};

export const motherHood = {
  files: [
    {
      title: "Cheek-hen",
      description: `Mother hens demonstrate one of the best motherhood practices in the animal Kingdom. They
      have behavioural habits that deviate from other chickens. After hatching eggs, their priority shifts from personal survival to protecting and ensuring the survival of her young chicks who can be threatened by predators and other hens in the flock. Even after hatching, Mother hens will gather and herd their own chicks to protect them against pecking and aggression from predators and other hens. She will gather the chicks under her wings to protect them when a threat is present. Mother hens provide education to their young chicks. Chicks will not drink water until they are taught or accidentally discover water by pecking at reflections and bubbles. Mothers will press their beaks into the water to help them discover and learn the
      drinking process.
      Mother hens and chicks use verbal commands to communicate. The mother hen's vocalizing makes the chicks aware of her location and keeps the chicks in her range. Hens and chicks will recognize their distinct sounds and stay together. However, mother hens do not have favourite chicks and do not show a preference for any of them. All these are outstanding
      attributes of a good mother.`,
      category: "Motherhood",
      year: "2020",
      img: [images.Cheek_hen, images.Cheek_hen3, images.Cheek_hen4],
    },
    {
      title: "Omidan",
      description: `Omidan is an elegant, well cultured and vibrant African queen.
      She is always happy. Even
      when she looks sad on the outside, you know that her inside is happy. Her hair defies gravity, Hair spun from finest thread, a statement of pride. Her skin absorbs the sun rays from the
      lightest to the darkest skin tones. Her aura oozes of goodwill and fortunes. A woman that can go any length to set her children on the right part which could see her sell her valuables. A woman with empathy towards her immediate and extended family. A queen that has
      unprecedented degree in Planning and management. A full package!`,
      category: "Motherhood",
      year: "2020",
      img: [images.omidanII, images.omidan, images.omidanIII],
    },
  ],

  title: "MotherHood",
  img: images.Cheek_hen,
};

export const others = [
  {
    title: "Brake fly",
    category: "other",
    img: [images.brakeFly],
    year: "2019",
  },
  {
    title: "Courage the cowardly dog",
    category: "other",
    img: [
      images.courageTheCowardly,
      images.courageTheCowardly2,
      images.courageTheCowardly3,
    ],
    year: "2019",
  },
  { title: "Eclipse", category: "other", img: [images.eclipse], year: "2021" },
  {
    title: "Fast N Fur-ious",
    category: "other",
    img: [images.fastNfurious, images.fastNfurious4],
    year: "",
  },
  {
    title: "Hare Potter",
    category: "other",
    img: [images.harePotter],
    year: "2020",
  },
  {
    title: "Hoof Hearted",
    category: "other",
    img: [images.hoofHearted, images.hoofHearted2, images.hoofHearted3],
    year: "",
  },
  {
    title: "Jack Sparrow",
    category: "other",
    img: [images.jackSparrow, images.jackSparrow2],
    year: "2021",
  },
  {
    title: "Jump Maestro",
    category: "other",
    img: [images.jumpMaestro, images.jumpMaestro2],
    year: "2020",
  },
  { title: "Lady Bug", category: "other", img: [images.ladyBug], year: "2021" },
  {
    title: "Madnifishcent",
    category: "other",
    img: [images.madnifishcent],
    year: "2019",
  },
  {
    title: "Mufasa",
    category: "other",
    img: [images.mafasa, images.mufasaII, images.mufasaIII],
    year: "2021",
  },
  {
    title: "Quail bird",
    category: "other",
    img: [images.quailBird, images.quailBird2],
    year: "2021",
  },
  {
    title: "Samba",
    category: "other",
    img: [images.samba, images.samba2, images.samba3],
    year: "2022",
  },
  {
    title: "Snoop Dawg",
    category: "other",
    img: [
      images.snoopDawg,
      images.snoopDawg2,
      images.snoopDawg3,
      images.snoopDawg4,
      images.snoopDawg5,
    ],
    year: "2021",
  },
  {
    title: "Voice of Voiceless",
    category: "other",
    img: [images.voiceOfVoiceless],
    year: "2020",
  },
];

export const category = [
  {
    title: "Carpie Diem",
    img: images.magniFishcent,
  },
  {
    title: "The Road Back To You",
    img: images.identity,
  },
  {
    title: "Outlook of Life",
    img: images.High_hopes_heron,
  },
  {
    title: "Owl Series",
    img: images._1owlClock,
  },
  {
    title: "3 Musketeers",
    img: images.Herald,
  },
  {
    title: "MotherHood",
    img: images.Cheek_hen,
  },
];

export const collection = [
  ...carpeDiem.files,
  ...roadBkToYou.files,
  ...outlookOfLife.files,
  ...owlSeries.files,
  ..._3muskeerteers.files,
  ...motherHood.files,
  ...others,
];

export const introduction = {
  quote: `“My work is aimed at bringing to your mind the strong feeling of your tender vision. This kind of art expresses aesthetics in Solid forms with surrealism and paranomasia as the principal theme. I’m interested in facilitating an interface between Subject and viewer, enabling you to navigate through my mind with your eyes, influenced by the unrelenting divergence of words and kaleidoscopic world around us. My work brings me peace and offers me a place to meditate and appreciate the Ultimate creator(God) and the beauty of the world”
  `,
  text: `For a couple of years, the artist Ayodeji Kingsley, has worked on although a pre-existing concept of art but a different lens of expression. He has thought through and taught truly the magnificent use of what others see as waste, brought them together in the praxis of fine aesthetics to express familiar ideas and entities such as: animals, tools, items and lots more.
  Though, Metal objects are rigid. They could still be bent to the will of an artist. They would do so in the right hands and with the right motives. Metals are lovely and they represent life in a really spectacular way, if only they are used fantastically. The evolution of man and the advancement of science and technology has shown a great deal how useful metals are and this had not left out art in all of its forms and systems.
  Kingsley has shown so much interest in turning scrap into incredible ideas plausible and almost indispensable in our world today. He has chosen a path that transforms static rubbish into static properties of adornments. The ornamental products of his art have helped lots of people to transfer their fantasies and illusions into substantial possessions. 
  I am a Co-creator in the direct image of the unequalled Ultimate Creator. - Ayodeji Kingsley `,
};

export const biography = {
  p1: `Ayodeji Kingsley is a Nigerian-born artist based in Derby, United Kingdom who predominantly works with metal in his sculptures. Ayodeji has a bachelor's degree in chemical engineering from Obafemi Awolowo University in Nigeria and a master's in environmental control and assessment from the University of Derby in the United Kingdom.
Ayodeji's artistic side was awakened at a young age, and he excelled in fine and creative art throughout his basic and secondary education. Over the years, he has developed his own imaginative and conceptual mind through drawing, paranomasia, making graphic illustrations and, most recently, sculpting. Initially, to supplement his income during university, his digital art practice began as a creative outlet and source of extra cash. However, the act of creation was far more than a mere means to an end. After completing his first degree, he consciously transitioned from a part-time pursuit to a dedicated artistic career by taking tutelage from artists in the country, self-development, and attending exhibitions.
`,
  p2: `As a passionate environmentalist/artist motivated by a deep fascination with salvaged metals' raw power and inherent beauty, Ayodeji has worked on a pre-existing art concept but a different expression lens. He has thought through and taught the magnificent use of what others see as waste, bringing them together in the praxis of pleasing aesthetics to express familiar ideas and entities such as animals, tools, items and lots more. He transforms discarded machinery, tools, and industrial detritus into evocative sculptures that explore themes of decay, rebirth, and the cyclical nature of existence.
Ayodeji Kingsley infuses mediums to facilitate an interface between the subject and the viewer.  Ayodeji draws inspiration from various sources, including everyday situations, nature, personal beliefs, and communication with other artists. The principal themes in his works are surrealism, paronomasia and deeply entrenched African Proverbs.  He strives to express these elements in his psychological approach to his artistic expression—a perfect reflection of the evolving and kaleidoscopic world around us.`,
  p3: `Throughout his career, Ayodeji has created a remarkable body of work that has received widespread recognition. His works have been shown in a variety of shows and galleries, both online and in person, across the globe. He has completed several commissioned works, including public sculptures in his home country, Nigeria. His work has captivated audiences across multiple platforms.`,
  // text: `Ayodeji kingsley is a Nigerian born artist who works predominantly with metal in his sculptures. Ayodeji holds a Bachelor’s degree in Chemical Engineering from Obafemi Awolowo University
  // Over the years he has developed his own imaginative and conceptual mind through writing of puns, drawing, making graphic illustrations and lately sculptures. He developed his undying passion for art after graduation by taking tutelage from artists in the country and also going for exhibitions.
  // Ayodeji Kingsley infuses mediums in order to facilitate an interface between subject and viewer but works mainly with metal. The principal themes in his works are surrealism and paronomasia. He tries to express both elements in his
  // psychological approach to his artistic expression. A perfect reflection for the evolving and kaleidoscopic world around us.`,
};
